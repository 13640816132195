import he from "he"
import converter from "number-to-words"
import Linkify from "linkifyjs/react"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import { Box, hexToRgb } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { generateTimestamp } from "@mobilemind/common/src/functions"

import ButtonSmall from "../../components/ButtonSmall"

export function CourseRevisionHistory({
  revisionHeight,
  assessment,
  activeCourse,
  courseQuiz,
  isHistoryExpanded,
  correctAnswers,
  setState,
  quizQuestions,
}) {
  return (
    <div id="revisionHistory">
      <ul style={{ height: revisionHeight }}>
        {assessment.revisions &&
          assessment.revisions.map((revision, index) => {
            let type = activeCourse.attributes.field_submission_type
            let revisionTitle = !index
              ? "Latest Submission"
              : converter.toWordsOrdinal(assessment.revisions.length - index) +
                " Submission"

            let submissionContent = (
              <>
                {type === "Text" && (
                  <em>
                    <Linkify tagName="span">
                      "{he.decode(revision.submission)}"
                    </Linkify>
                  </em>
                )}
                {type === "URL" && (
                  <strong>
                    <Linkify tagName="span">{he.decode(revision.url)}</Linkify>
                  </strong>
                )}

                {type === "File" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    {revision.field_file_submission__revision_id
                      .split(", ")
                      .map((file, index) => {
                        const fileExtension = file.split(".").pop()

                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: 10,
                              marginBottom: 10,
                            }}
                            href={process.env.REACT_APP_API_URL + file}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {fileExtension.toLowerCase() !== "png" &&
                            fileExtension.toLowerCase() !== "jpg" &&
                            fileExtension.toLowerCase() !== "jpeg" ? (
                              <img
                                src={require("../../img/file-" +
                                  fileExtension.toLowerCase() +
                                  ".svg")}
                                alt={fileExtension}
                                width={25}
                                height={25}
                                style={{ marginRight: 5 }}
                              />
                            ) : (
                              <img
                                src={process.env.REACT_APP_API_URL + file}
                                alt={fileExtension}
                                width={25}
                                height={25}
                                style={{ marginRight: 5, borderRadius: 8 }}
                              />
                            )}

                            <strong>
                              {he.decode(
                                decodeURIComponent(file).split("/").pop()
                              )}
                            </strong>
                          </a>
                        )
                      })}
                  </Box>
                )}

                {type === "Image" && (
                  <span
                    className="imageWrapper"
                    onClick={() => {
                      setState({ activeImage: revision })
                    }}
                  >
                    <div
                      className="submissionImage"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          revision.image +
                          ")",
                      }}
                    />
                  </span>
                )}
              </>
            )

            return (
              <li
                id={!index ? "latest" : "revision-" + index}
                key={revision.vid}
              >
                <header
                  style={{
                    textTransform: "uppercase",
                    fontSize: 10,
                    fontStyle: "normal",
                  }}
                >
                  {revisionTitle}
                </header>
                {revision.field_status__revision_id !== "Submitted" &&
                  revision.field_comments__revision_id && (
                    <Linkify tagName="div" className="feedback">
                      <span className="timestamp">
                        <strong>Reviewer Feedback</strong>
                        <br />
                        <span className="date">
                          {generateTimestamp(revision.review_date)}
                        </span>
                      </span>
                      <span className="feedbackContent">
                        {!revision.user_picture.includes("default_temp") ? (
                          <span
                            className="reviewerImage"
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_API_URL +
                                revision.user_picture +
                                ")",
                            }}
                          />
                        ) : (
                          <UserInitial
                            fontSize={16}
                            size={40}
                            initials={
                              revision.reviewer_first_name
                                .charAt(0)
                                .toUpperCase() +
                              revision.reviewer_last_name
                                .charAt(0)
                                .toUpperCase()
                            }
                          />
                        )}

                        <div
                          style={{
                            backgroundColor: hexToRgb(
                              theme.palette.secondary.main
                            )
                              .replace("rgb", "rgba")
                              .replace(")", ",.45)"),
                          }}
                          className="quote"
                        >
                          <SanitizedHTML
                            quote={true}
                            html={he.decode(
                              revision.field_comments__revision_id
                            )}
                          />
                        </div>
                      </span>
                    </Linkify>
                  )}

                <p className="submission">
                  <span className="timestamp">
                    <strong>Your Answer</strong>
                    <br />
                    <span className="date">
                      {generateTimestamp(revision.created)}
                    </span>
                  </span>
                  <span className="submissionInfo">{submissionContent}</span>
                </p>
              </li>
            )
          })}
      </ul>
      {courseQuiz &&
        assessment &&
        assessment.attributes.field_quiz_score &&
        correctAnswers &&
        quizQuestions && (
          <div className="flexRow">
            <div className="quizResults">
              <div className="flexRow">
                <div className="score">
                  <strong>{correctAnswers.length}</strong> out of{" "}
                  <strong>{quizQuestions.length}</strong> correct
                </div>
                {activeCourse.quiz &&
                  activeCourse.quiz.attributes.field_show_missed_questions && (
                    <ButtonSmall
                      onClick={() => {
                        setState({ showQuizResults: true })
                      }}
                    >
                      View Results
                    </ButtonSmall>
                  )}
              </div>
              <div className="progressBarWrapper">
                {assessment.quizAnswers.map((answer) => {
                  return (
                    <div
                      style={{
                        height: 15,
                        flex: 1,
                        borderLeft:
                          quizQuestions.length > 1
                            ? "2px solid #ebebeb"
                            : "none",
                        backgroundColor: answer.attributes.field_correct
                          ? "#24DC91"
                          : "#f57f7f",
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}

      {assessment.revisions && assessment.revisions.length > 1 && (
        <footer>
          <ButtonSmall
            theme={theme}
            onClick={() => {
              setState({
                isHistoryExpanded: isHistoryExpanded ? false : true,
              })
            }}
          >
            {isHistoryExpanded ? (
              <>Hide Previous Submissions</>
            ) : (
              <>Show Previous Submissions</>
            )}
          </ButtonSmall>
        </footer>
      )}
    </div>
  )
}
