import { Component } from "react"
import { connect } from "react-redux"
import Masonry from "react-masonry-component"

import WidgetCourseList from "./WidgetCourseList"
import WidgetBadge from "./WidgetBadge"
import WidgetAchievements from "./WidgetAchievements"
import WidgetSocial from "./WidgetSocial"
import WidgetSupport from "./WidgetSupport"
import WidgetLearningPath from "./WidgetLearningPath"
import WidgetAnnouncement from "./WidgetAnnouncement"
import WidgetQuickLinks from "./WidgetQuickLinks"
import WidgetFeedback from "./WidgetFeedback"
import WidgetGoal from "./WidgetGoal"
import WidgetSync from "./WidgetSync"
import WidgetMobileDownload from "./WidgetMobileDownload"

import { changeNavbar } from "../../store/reducers/navbar"
import Loading from "@mobilemind/common/src/components/Loading"
import classNames from "classnames"
import { getAchievements } from "../rewind/rewindSlice"
import theme from "@mobilemind/common/src/theme/theme"
import moment from "moment"

import {
  startProductTour,
  getAnnouncements,
  getSingleAssessment,
  getAssessmentsByStatus,
  getQuickLinks,
  getRecentUserBadge,
} from "../../actions"

import {
  fetchUpcomingEvents,
  getLPData,
  getInProgressCourses,
} from "./dashboardSlice"

import { isCourseCategoryExcluded } from "../../functions"
import { getLearningPaths } from "../learningPaths/learningPathsSlice"

import "../../styles/dashboard.scss"
import "../../styles/rewind.scss"
import WidgetMandatedTraining from "./WidgetMandatedTraining"
import { FeatureName } from "../../store/reducers/session"

const mapStateToProps = ({
  rewind,
  dashboard,
  goals,
  session,
  onboarding,
  courses,
  assessments,
  productTour,
  categories,
  learningPaths,
  mandatedTraining,
  announcements,
  sidebar,
}) => {
  let widgets = []

  let learningPathWidgetInProgress = {
    label: "Next Steps",
    type: "learningPath",
    status: "in_progress",
    targetTab: "learningPaths",
    learningPath: dashboard.learningPathInProgress,
  }

  // Current Learning Path Progress
  if (dashboard.learningPathInProgress) {
    widgets.push(learningPathWidgetInProgress)
  }

  let activeAnnouncements = announcements.data.filter((announcement) => {
    return (
      announcement.field_announcement_type &&
      announcement.field_announcement_type[0]
    )
  })

  // Important Announcements
  activeAnnouncements.forEach((announcement) => {
    let type = announcements.types.find(
      (type) =>
        type.tid[0].value === announcement.field_announcement_type[0].target_id
    )
    if (type.name[0].value === "Important") {
      let widget = {
        type: "announcement",
        isImportant: true,
        announcement: announcement,
      }
      widgets.push(widget)
    }
  })

  // Feedback Widget
  if (dashboard.feedback) {
    widgets.push({ type: "feedback", course: dashboard.feedback })
  }

  // Goals!
  if (dashboard.goalsData) {
    dashboard.goalsData.forEach((goalPath) => {
      if (
        goalPath.field_user_lp_status === "in_progress" ||
        !goalPath.field_user_lp_status
      ) {
        widgets.push({
          type: "goal",
          goalPath,
        })
      }
    })
  }

  // Go through announcements
  activeAnnouncements.forEach((announcement) => {
    let type = announcements.types.find(
      (type) =>
        announcement.field_announcement_type[0] &&
        type.tid[0].value === announcement.field_announcement_type[0].target_id
    )

    // Hide Survey announcements for now
    let widget = type.name[0].value !== "Survey" && {
      type: "announcement",
      label: type.name[0].value,
      announcement,
    }

    // Only add Featured Courses if the teacher hasn't already started it, and if it's not an excluded category
    if (type.name[0].value === "Featured Course") {
      let assessmentForCourse = assessments.all.find(
        (assessment) =>
          assessment.relationships.field_course.data.id ===
            announcement.field_course[0] &&
          announcement.field_course[0].target_uuid
      )
      let course =
        courses.courseData &&
        courses.courseData.find(
          (course) =>
            announcement.field_course[0] &&
            course.id === announcement.field_course[0].target_uuid
        )
      let isExcluded =
        course &&
        session.excludedCategories &&
        isCourseCategoryExcluded(
          course,
          categories.data,
          session.excludedCategories
        )

      if (!assessmentForCourse && !isExcluded) {
        widgets.push(widget)
      }
    } else if (type.name[0].value === "Featured Learning Path") {
      let ULPForPath = session.userLearningPaths.find(
        (ulp) =>
          announcement.field_learning_path[0] &&
          ulp.relationships.field_learning_path.data.id ===
            announcement.field_learning_path[0].target_uuid
      )

      if (!ULPForPath) {
        widget.learningPath = learningPaths.data.find(
          (path) =>
            announcement.field_learning_path[0] &&
            path.id === announcement.field_learning_path[0].target_uuid
        )
        widgets.push(widget)
      }
    } else if (type.name[0].value !== "Important") {
      widgets.push(widget)
    }
  })

  return {
    session,
    widgets,
    userGoals: goals,
    announcements,
    mandatedTraining,
    learningPaths,
    productTour,
    sidebar,
    onboarding,
    rewind,
    dashboard,
  }
}

const mapDispatchToProps = {
  startProductTour,
  getAnnouncements,
  getQuickLinks,
  getAssessmentsByStatus,
  getRecentUserBadge,
  getSingleAssessment,
  fetchUpcomingEvents,
  getLPData,
  getAchievements,
  getLearningPaths,
  getInProgressCourses,
  changeNavbar,
}

function DashboardLayout(props) {
  return <DashboardContainer {...props} theme={theme} />
}

class DashboardContainer extends Component {
  state = {
    dashboardReady: false,
    allFetched: true,
    height: 0,
    width: 0,
  }

  componentDidMount = async () => {
    this.props.changeNavbar("learn")
    if (this.props.session.user.jobTitle) {
      this.prepareDashboard()
    }
  }

  reRender = () => {
    this.setState({ dashboardReady: false })
    this.setState({ dashboardReady: true })
  }

  handleResize = () => {
    if (this.state.width > 1440 && window.innerWidth <= 1440) {
      this.reRender()
    }
    if (this.state.width < 1440 && window.innerWidth >= 1440) {
      this.reRender()
    }
    if (this.state.width > 667 && window.innerWidth <= 667) {
      this.reRender()
    }
    if (this.state.width < 667 && window.innerWidth >= 667) {
      this.reRender()
    }

    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  prepareDashboard = async () => {
    const { session } = this.props
    this.setState({ allFetched: false })
    window.addEventListener("resize", this.handleResize)
    // Get rewind achievements
    this.props.getAchievements(session.user.id)

    // Get announcements
    this.props.getAnnouncements().then(() => {
      this.reRender()
    })

    // Get quick links
    this.props.getQuickLinks()

    // Get user events that are in the future
    this.props.fetchUpcomingEvents().then(() => {
      this.reRender()
    })

    let promises = [await this.props.getLPData()]

    // See if they have any assessments first before we try to fetch badges, assessments, learning paths
    let hasAssessment = await this.props.getSingleAssessment()
    // Get rid of most recent badge for now
    // promises.push(this.props.getRecentUserBadge())

    if (hasAssessment.length) {
      promises.push(this.props.getAssessmentsByStatus("saved_for_later", 3))
      // Get rid of "Jump Back In" for now
      // promises.push(this.props.getAssessmentsByStatus("in_progress", 3))
      promises.push(this.props.getInProgressCourses())
    }

    Promise.all(promises).then(async () => {
      this.setState({ allFetched: true, dashboardReady: true })
      // Start the product tour if they haven't already
      if (
        !session.user.attributes.field_product_tour_started ||
        moment(session.user.attributes.field_product_tour_started).isBefore(
          moment("02/28/23")
        )
      ) {
        if (!this.props.productTour.hasStarted) {
          this.props.startProductTour()
        }
      }
    })
  }

  render() {
    const { widgets, dashboard, session, theme, mandatedTraining } = this.props
    const { dashboardReady } = this.state
    const hideEventsWidget =
      session.group &&
      session.group.field_hide_events &&
      session.group.field_hide_events[0]?.value

    let feedbackWidget = widgets.find((widget) => widget.type === "feedback")
    let goals = widgets.filter((widget) => widget.type === "goal")
    let learningPathWidget = widgets.find(
      (widget) => widget.type === "learningPath"
    )
    let announcements = widgets
      .filter((widget) => widget.type === "announcement")
      .filter((widget) => !widget.isImportant)
    let importantAnnouncements = widgets
      .filter((widget) => widget.type === "announcement")
      .filter((widget) => widget.isImportant)

    let notReady = !this.state.allFetched
    return (
      <div className={classNames("page dashboard", notReady && "loading")}>
        {notReady && (
          <Loading
            color={theme.palette.secondary.main}
            inline={true}
            message="Building your dashboard..."
          />
        )}

        {dashboardReady && (
          <>
            <Masonry elementType={"ul"} options={{ transitionDuration: 0.3 }}>
              {importantAnnouncements.length > 0 &&
                importantAnnouncements.map((announcement, index) => {
                  return (
                    <WidgetAnnouncement
                      theme={theme}
                      key={index}
                      widget={announcement}
                    />
                  )
                })}

              {dashboard.quickLinks && dashboard.quickLinks.attributes && (
                <div className="widgetWrapper">
                  <WidgetQuickLinks
                    theme={theme}
                    widget={{
                      type: "quick-links",
                      quickLinks: dashboard.quickLinks,
                    }}
                  />
                </div>
              )}

              {session.user.attributes.field_courses_completed > 0 && (
                <WidgetAchievements
                  theme={theme}
                  widget={{ type: "achievements" }}
                />
              )}

              {mandatedTraining.data &&
                mandatedTraining.data.length > 0 &&
                mandatedTraining.outOfCompliance && (
                  <WidgetMandatedTraining mandatedTraining={mandatedTraining} />
                )}

              {dashboard.sync && !hideEventsWidget && (
                <WidgetSync sync={dashboard.sync} theme={theme} />
              )}

              {feedbackWidget && (
                <WidgetFeedback theme={theme} widget={feedbackWidget} />
              )}
              {dashboard.inProgress &&
                dashboard.inProgress.assessments.length > 0 && (
                  <WidgetCourseList
                    theme={theme}
                    widget={dashboard.inProgress}
                  />
                )}
              {dashboard.savedForLater &&
                dashboard.savedForLater.assessments.length > 0 && (
                  <WidgetCourseList
                    theme={theme}
                    widget={dashboard.savedForLater}
                  />
                )}

              {goals.length > 0 ? (
                <>
                  {goals.map((goal, index) => {
                    return (
                      <WidgetGoal theme={theme} key={index} widget={goal} />
                    )
                  })}
                </>
              ) : (
                <>
                  {learningPathWidget && (
                    <WidgetLearningPath
                      theme={theme}
                      widget={learningPathWidget}
                    />
                  )}
                </>
              )}

              {dashboard.recentBadge && (
                <WidgetBadge theme={theme} widget={dashboard.recentBadge} />
              )}

              {announcements.length > 0 &&
                announcements.map((announcement, index) => {
                  return (
                    <WidgetAnnouncement
                      key={index}
                      theme={theme}
                      widget={announcement}
                    />
                  )
                })}
              {window.location.href.includes("mobilemind.io") && (
                <WidgetSocial
                  theme={theme}
                  session={session}
                  widget={{ type: "social" }}
                />
              )}

              {mandatedTraining.data &&
                mandatedTraining.data.length > 0 &&
                !mandatedTraining.outOfCompliance && (
                  <WidgetMandatedTraining
                    inCompliance={true}
                    mandatedTraining={mandatedTraining}
                  />
                )}

              {(session.enabledAddOns.includes(FeatureName.OnDemand) ||
                session.enabledAddOns.includes(FeatureName.Events)) && (
                <WidgetSupport theme={theme} widget={{ type: "support" }} />
              )}

              {!session.user.relationships.field_device_tokens.data.length ? (
                <WidgetMobileDownload
                  theme={theme}
                  collection={session.collection}
                  widget={{ type: "mobile-reminder" }}
                />
              ) : (
                <></>
              )}
            </Masonry>
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
