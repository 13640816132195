import { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store/store"

import Sidebar from "@mobilemind/common/src/components/SidebarSectioned"
import {
  setIsGCalModalOpen,
  setIsOutlookModalOpen,
  setSidebarItem,
} from "../../store/reducers/calendar"
import {
  claimRecommended,
  fetchRecommendedEvents,
  fetchExternalPDRequests,
  startNewRequest,
  toggleRequestModal,
} from "../../store/reducers/externalPD"
import { changeNavbar } from "../../store/reducers/navbar"
import CalendarLayout from "./calendar/CalendarLayout"

import RecommendedEvents from "./externalPD/RecommendedEvents"
import RequestCreditModal from "./externalPD/RequestCreditModal"
import ExternalRequestContainer from "./externalPD/ExternalRequestContainer"
import { FeatureName } from "../../store/reducers/session"

const EventsContainer = () => {
  const { calendar, externalPD, session } = useSelector(
    (state: RootState) => state
  )

  const { orgRoles, groupRoles } = session
  const canSchedule =
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-scheduler") ||
    groupRoles.includes("group-admin") ||
    groupRoles.includes("group-scheduler")

  const dispatch = useAppDispatch()
  const [hasSwitchedNavbar, switchNavbar] = useState(false)

  useEffect(() => {
    !externalPD.unapproved.fetched && dispatch(fetchExternalPDRequests({}))
    !externalPD.approved.fetched &&
      dispatch(fetchExternalPDRequests({ status: "accepted" }))
    if (!hasSwitchedNavbar) {
      dispatch(changeNavbar("events"))
      switchNavbar(true)
    }
  }, [
    calendar.categories,
    hasSwitchedNavbar,
    externalPD.unapproved,
    externalPD.approved.fetched,
    dispatch,
  ])

  const pendingCount = externalPD.unapproved.data.filter(
    (event: any) =>
      event.field_status_value === "pending" ||
      event.field_status_value === "awaiting_pre_appr" ||
      event.field_status_value === "pre_appr_granted"
  ).length
  const declinedCount = externalPD.unapproved.data.filter(
    (event: any) =>
      event.field_status_value === "declined" ||
      event.field_status_value === "pre_approval_declined"
  ).length
  const approvedCount = externalPD.approved.total_records

  const { field_calendar_integration } = session.user.attributes

  const hasExternalAccess =
    session.enabledAddOns.includes(FeatureName.Events) ||
    session.enabledAddOns.includes(FeatureName.OnDemand)

  const hasIntegrationAccess = session.enabledAddOns.includes(
    FeatureName.Events
  )

  const sidebarSections: any[] = [
    {
      title: "Calendar",
      children: [
        {
          title: "Event Calendar",
          iconClass: "calendar",
          onClick: () => {
            dispatch(setSidebarItem("Event Calendar"))
          },
        },
      ],
    },
  ]

  // External PD
  hasExternalAccess &&
    sidebarSections.push({
      title: "External Events",
      children: [
        {
          title: "Recommended",
          iconClass: "recommended",
          onClick: () => {
            dispatch(setSidebarItem("Recommended"))
          },
        },
        {
          title: "Pending",
          iconClass: "external-pending",
          onClick: () => {
            dispatch(setSidebarItem("Pending"))
          },
          count: pendingCount,
        },
        {
          title: "Accepted",
          iconClass: "external-accepted",
          onClick: () => {
            dispatch(setSidebarItem("Accepted"))
          },
          count: approvedCount,
        },
        {
          title: "Declined",
          iconClass: "external-declined",
          onClick: () => {
            dispatch(setSidebarItem("Declined"))
          },
          count: declinedCount,
        },
      ],
    })

  // Integrations
  hasIntegrationAccess &&
    sidebarSections.push({
      title: "Calendar Integrations",
      children: [
        {
          title: "Google",
          iconClass:
            !field_calendar_integration ||
            !field_calendar_integration.includes("google")
              ? "google"
              : "google checked",
          onClick: () => {
            dispatch(setIsGCalModalOpen(true))
          },
        },
        {
          title: "Outlook",
          iconClass:
            !field_calendar_integration ||
            !field_calendar_integration.includes("outlook")
              ? "outlook"
              : "outlook checked",
          onClick: () => {
            dispatch(setIsOutlookModalOpen(true))
          },
        },
      ],
    })

  if (session.group) {
    const { field_external_pd_access } = session.group
    if (
      field_external_pd_access &&
      field_external_pd_access[0] &&
      field_external_pd_access[0].value
    ) {
      sidebarSections.forEach((section: any) => {
        if (section.title === "External Events") {
          section.children.unshift({
            title: "Request Approval",
            iconClass: "raised-hand",
            onClick: () => {
              dispatch(startNewRequest())
              dispatch(toggleRequestModal(true))
            },
          })
        }
      })
    }
  }

  let action
  if (session.enabledAddOns.includes(FeatureName.Events) && canSchedule) {
    action = {
      href: process.env.REACT_APP_HQ_URL + "/events",
      label: "Manage Events",
    }
  }

  return (
    <div className="page events-layout">
      <Sidebar
        enabledAddOns={session.enabledAddOns}
        activeItem={calendar.sidebarActiveItem}
        action={action}
        sections={sidebarSections}
      />

      {calendar.sidebarActiveItem === "Event Calendar" && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CalendarLayout isRequestModalOpen={externalPD.currentRequest.isOpen} />
      )}

      <RequestCreditModal
        close={() => dispatch(toggleRequestModal(false))}
        open={externalPD.currentRequest.isOpen}
      />
      {calendar.sidebarActiveItem === "Recommended" && (
        <RecommendedEvents
          fetchRecommendedEvents={fetchRecommendedEvents}
          openEvent={claimRecommended}
          toggleModal={toggleRequestModal}
          recommendedEvents={externalPD.recommended}
        />
      )}
      {(calendar.sidebarActiveItem === "Pending" ||
        calendar.sidebarActiveItem === "Accepted" ||
        calendar.sidebarActiveItem === "Declined") && (
        <ExternalRequestContainer
          status={calendar.sidebarActiveItem.toLowerCase()}
        />
      )}
    </div>
  )
}

export default EventsContainer
