import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { setMaintenanceMode } from "@mobilemind/common/src/actions/session"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../store"

export const unmasqueradeUser = createAsyncThunk(
  "session/unmasqueradeUser",
  async () => {
    await fetchWrapper.get("/api/unmasquerade")
    window.location.reload()
  }
)

export enum FeatureName {
  OnDemand = "on_demand",
  Events = "events",
  Badges = "badges",
  Genie = "genie",
  Observations = "observations",
}

type InitialState = {
  certificates: {
    data: any[]
    fetched: boolean
  }
  collection: Object
  earnedXP: number
  estimatedTimeSpent: number
  excludedCategories: any[]
  memberships: any[]
  fetched: any
  group: any
  groupRoles: string[]
  isLoggedIn: boolean
  orgRoles: string[]
  isPartner: boolean
  mobileToken: {
    current_user: any[]
  }
  token: string | null
  user: any
  roles: any[]
  subgroup: any
  subgroups: any[]
  trial: boolean
  userBadges: any[]
  userId: number | null
  userLearningPaths: any[]
  userPicture: any
  netPromoterScore: number | null
  netPromoterScoreFetched: boolean
  activeUserLearningPath: any
  isMasquerading: boolean
  maintenanceMode: boolean
  enabledAddOns: string[]
}

const initialState: InitialState = {
  certificates: {
    data: [],
    fetched: false,
  },
  collection: {},
  memberships: [],
  earnedXP: 0,
  estimatedTimeSpent: 0,
  excludedCategories: [],
  fetched: {},
  group: {},
  groupRoles: [],
  isLoggedIn: false,
  orgRoles: [],
  isPartner: false,
  mobileToken: {
    current_user: [],
  },
  token: null,
  user: {},
  roles: [],
  subgroup: {},
  subgroups: [],
  trial: false,
  userBadges: [],
  userId: null,
  userLearningPaths: [],
  userPicture: null,
  netPromoterScore: null,
  netPromoterScoreFetched: false,
  activeUserLearningPath: null,
  isMasquerading: false,
  maintenanceMode: false,
  enabledAddOns: [],
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    getUserId: (state, action) => {
      state.userId = action.payload.userId
      state.trial = action.payload.trial
      state.roles = action.payload.roles
      state.estimatedTimeSpent = action.payload.timeSpent
      state.collection = action.payload.collection
      state.memberships = action.payload.memberships

      state.isLoggedIn = action.payload.isLoggedIn
      state.isMasquerading = action.payload.isMasquerading
      state.enabledAddOns = action.payload.enabledAddOns

      state.orgRoles = Object.keys(action.payload.orgRoles)
      if (action.payload.subgroup && action.payload.subgroup[1]) {
        state.groupRoles = Object.keys(action.payload.subgroup[1])
      }

      if (state.orgRoles.includes("partner-admin")) {
        state.isPartner = true
      }

      state.group = action.payload.group
      state.excludedCategories =
        action.payload.group.field_categories_to_exclude
      state.subgroup = action.payload.subgroup && action.payload.subgroup[0]
      state.subgroups = [
        action.payload.subgroups ? action.payload.subgroups : { data: [] },
      ]
    },
    userIsLoggedIn: (state, action: any) => {
      const user = action.payload
      state.user = user

      if (action.payload.payload) {
        action = action.payload
      }

      state.user = action.payload.payload
        ? action.payload.payload
        : action.payload

      action.meta.included &&
        action.meta.included.forEach((included: any) => {
          if (included.type === "file--image") {
            let field_cover_photo =
              action.payload.relationships.field_cover_photo.data
            let coverPhotoId =
              field_cover_photo &&
              field_cover_photo.meta.drupal_internal__target_id

            if (
              coverPhotoId &&
              included.attributes.drupal_internal__fid === coverPhotoId
            ) {
              state.user.coverPhoto = included
            }

            let user_picture = action.payload.relationships.user_picture.data
            let userPictureId =
              user_picture && user_picture.meta.drupal_internal__target_id

            if (
              userPictureId &&
              included.attributes.drupal_internal__fid === userPictureId
            ) {
              state.userPicture = included
            }

            if (included.attributes.uri.value.includes("rewind")) {
              state.user.rewindImage = included
            }
          }
          if (included.type === "taxonomy_term--job_titles") {
            state.user.jobTitle = included
          }
          if (included.type === "taxonomy_term--learning_buddy") {
            state.user.learningBuddy = included
          }
        })
    },
    userIsAnonymous: (state, action) => {
      state.user = action.payload
    },
    updateIntegrationSettings: (state, action) => {
      state.user.attributes.field_calendar_integration = action.payload
    },
    getNPSScore: (state, action) => {
      state.netPromoterScore = action.payload
      state.netPromoterScoreFetched = true
    },
    getToken: (state, action) => {
      state.token = action.payload
    },
    getCertificate: (state, action) => {
      const ULP = action.payload.included?.find(
        (included: any) =>
          included.type === "user_learning_path--user_learning_path"
      )

      let certificate = action.payload.data
      certificate.learning_path_completed = ULP?.attributes?.created
      if (certificate.relationships.field_time_spent) {
        const timeSpentIds =
          certificate.relationships.field_time_spent.data.map(
            (paragraph: any) => paragraph.id
          )
        certificate.timeSpent = action.payload.included
          ?.filter((included: any) => included.type === "paragraph--time_spent")
          .filter((paragraph: any) => timeSpentIds.includes(paragraph.id))
      }

      state.certificates.data.push(certificate)
    },
    getCertificates: (state, action) => {
      const thumbnails =
        action.payload.included &&
        action.payload.included.filter(
          (included: any) => included.type === "file--image"
        )

      // Find any thumbnails
      action.payload &&
        action.payload.data.forEach((certificate: any) => {
          if (certificate.relationships.field_thumbnail.data) {
            certificate.thumbnail = thumbnails.find(
              (thumbnail: any) =>
                thumbnail.id ===
                certificate.relationships.field_thumbnail.data.id
            )
          }
        })

      state.certificates.data = action.payload.data
      state.certificates.fetched = action.payload.fetched
    },
    getActiveUserLearningPath: (state, action) => {
      state.activeUserLearningPath = action.payload
    },
    getUserLearningPaths: (state, action) => {
      action.payload.data.forEach((userPath: any) => {
        if (
          !state.userLearningPaths.some(
            (existingPath) => existingPath.id === userPath.id
          )
        ) {
          if (action.payload.included) {
            userPath.learningPath = action.payload.included.find(
              (included: any) =>
                included.type === "learning_path--learning_path" &&
                included.id ===
                  userPath.relationships.field_learning_path.data.id
            )
            userPath.user = action.payload.included.find(
              (user: any) =>
                user.id === userPath.relationships.field_user.data.id
            )

            const timeSpentIds =
              userPath.relationships.field_time_spent.data.map(
                (paragraph: any) => paragraph.id
              )

            userPath.timeSpent =
              action.payload.included &&
              action.payload.included
                .filter(
                  (included: any) => included.type === "paragraph--time_spent"
                )
                .filter((paragraph: any) => timeSpentIds.includes(paragraph.id))

            state.userLearningPaths.push(userPath)
          }
        }
      })
      state.fetched.userLearningPaths = action.payload.fetched
    },
    updateUser: (state, action) => {
      action.payload.learningBuddy = state.user.learningBuddy
      state.user = action.payload.user ? action.payload.user : action.payload
      if (action.payload.jobTitle) {
        state.user.jobTitle = action.payload.jobTitle
      }
    },
    updateUserPicture: (state, action) => {
      state.userPicture = action.payload
      if (action.payload) {
        state.user.userMobilePicture = action.payload.attributes.uri.url
      }
    },
    updateLearningBuddy: (state, action) => {
      state.user.learningBuddy = action.payload
    },
    updateCoverPhoto: (state, action) => {
      state.user.coverPhoto = action.payload.attributes.uri.url
    },
    getLoggedInStatus: (state, action) => {
      state.isLoggedIn = action.payload
    },
    logout: (state) => {
      state.user = null
      state.isLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setMaintenanceMode, (state, action) => {
      state.maintenanceMode = action.payload
    })
  },
})

export const selectMaintenanceMode = (state: RootState) =>
  state.session.maintenanceMode

export default sessionSlice.reducer
