import { useDispatch } from "react-redux"
import initialState from "./initialState"
import reducers from "./reducers"
import { configureStore } from "@reduxjs/toolkit"

// Middleware
const store = configureStore({
  reducer: reducers,
  preloadedState: initialState,
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
